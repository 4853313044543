import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import routesMap from '../../Routes';
import { A } from './homeStyledComponents';
import {
  margins, fontSizes, colors, mobileThresholdPixels,
} from '../styledComponents';
import { Title1 } from './v2/styledComponents';

import { partnersData } from './partnersData';

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px 5px;
  }
`;

const CallToAction = styled.div`
  padding: ${margins.l} 0px;
  align-self: center;
  color: ${colors.navy};
  font-size: ${fontSizes.xl};
  font-weight: 300;
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.m};
  }
`;

const Img = styled.img`
  max-height: 200px;
  height: ${(props) => props.height || 'auto'};
  min-width: ${(props) => props.width || '150px'};
  max-width: 300px;
  margin: ${margins.l};

  &:hover {
    transform: scale(1.1);
  }

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m};
  }
`;

const PartnersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  max-width: 1200px;
  margin: auto;
`;

const ImgContainer = styled.div`
  width: 392px;
  min-height: 222px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    max-width: 392px;
  }
`;

const Partners = () => (
  <Container>
    <Title1>Ils nous font confiance</Title1>

    <PartnersContainer>
      {partnersData.map((partner, index) => (
        <ImgContainer key={partner.caption}>
          <Img
            src={partner.img}
            alt={partner.caption}
            width={partner.width}
            height={partner.height}
            key={partner.caption}
            loading={index > 11 ? 'lazy' : 'eager'}
          />
        </ImgContainer>
      ))}
    </PartnersContainer>

    <Link to={routesMap.WhyBecomePartner.url} style={{ flex: 1, textAlign: 'center' }}>
      <A as="span">Pourquoi choisir Tilli comme partenaire retouches ?</A>
    </Link>

    <CallToAction>
      Vous êtes une marque ?
      <br />
      Contactez-nous à l’adresse partenariat@tilli.fr
    </CallToAction>
  </Container>
);

export default Partners;
